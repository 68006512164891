<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Informe Sedes</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>INFORME PARA EL SEDES</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <label for="sucursal"><strong>SUCURSAL: </strong></label>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="fecha_inicio"><strong>FECHA INCIO: </strong></label>
            <Calendar
              id="fecha_inicio"
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="fecha_fin"><strong>FECHA FIN: </strong></label>
            <Calendar
              id="fecha_fin"
              v-model="fecha_fin"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="medico_id"><strong>MÉDICO: </strong></label>
            <Dropdown
              v-model="medicoSelected"
              :options="medicos"
              optionLabel="nombre_completo"
              optionValue="id"
              :filter="true"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>ACCIONES: </strong></label>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              v-tooltip.top="'Buscar'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="reporte_sedes"
          key="id"
          :value="sedes"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar..."
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Historia DescargarEXCEL' in auth.user.permissions"
                  label="EXCEL Exportar"
                  icon="pi pi-file-excel"
                  class="p-button-outlined p-button-success"
                  v-tooltip.top="'Imprimir Reporte Excel'"
                  :loading="exportando"
                  :disabled="exportando"
                  @click="exportHistoria"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid">
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column
            class="text-right"
            field="id"
            header="CÓD"
            :sortable="true"
            ></Column>
            <Column field="fecha" header="FECHA"> </Column>
          <Column field="nombre_cliente" header="PACIENTE"> </Column>
          <Column field="fecha_nacimiento" header="EDAD">
            <template #body="{ data }">
              {{ edadMesesCliente(data.fecha_nacimiento) }}
            </template>
          </Column>
          <Column field="sexo" header="GENERO"> </Column>
          <Column field="servicio" header="SERVICIO"> </Column>
          <Column field="diagnostico" header="DIAGNOSTICO"> </Column>
          <Column field="nombre_medico" header="MÉDICO"></Column>
          <Column field="estado" header="ESTADO">
            <template #body="{ data }">
              <span
                class="customer-badge"
                :class="{
                  'status-proposal': data.estado === 0,
                  'status-new': data.estado === 1,
                  'status-qualified': data.estado === 2,
                  'status-unqualified': data.estado === 3,
                }"
              >
                {{ textoEstado(data.estado) }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
  
<script>
import { FilterMatchMode } from "primevue/api";
import HistoriaService from "@/service/HistoriaService";
import SucursalService from "@/service/SucursalService";
import { useAuth } from "@/stores";
import MedicoService from "@/service/MedicoService";

export default {
  data() {
    return {
      fecha_inicio: null,
      fecha_fin: null,
      enviando: false,
      sedes: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      medicoSelected: 0,
      medicos: [{ id: 0, nombre_completo: "TODOS" }],
      exportando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  historiaService: null,
  sucursalService: null,
  auth: null,
  medicoService: null,

  created() {
    this.historiaService = new HistoriaService();
    this.sucursalService = new SucursalService();
    this.auth = useAuth();
    this.medicoService = new MedicoService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarMedicos();
  },
  methods: {
    edadMesesCliente(fecha) {
      let fecha_nacimiento = new Date(fecha);
      let fecha_actual = new Date();
      let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
      let meses = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
      if (
        meses < 0 ||
        (meses === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
      ) {
        edad--;
        meses += 12;
      }
      return edad + " AÑOS Y " + meses + " MESES";
    },
    exportHistoria() {
      if (this.sedes.length > 0) {
        let datos = {
          sucursal_id: this.sucursalSelected,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          medico_id: this.medicoSelected,
        };
        this.historiaService.exportarHistorias(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación exitosa",
            detail: "Se ha exportado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    cargarMedicos() {
      this.medicoService.getMedicosAll().then((data) => {
        this.medicos = data.medicos || [];
        this.medicos.unshift({ id: 0, nombre_completo: "TODOS" });
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BuscarFiltro() {
      this.sedes = [];
      this.enviando = true;
      this.cargarReporteHistorias();
    },
    cargarReporteHistorias() {
      let datos = {
        sucursal: this.sucursalSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        medico_id: this.medicoSelected,
      };
      this.historiaService
        .filtrarHistorias(datos)
        .then((data) => {
          this.sedes = data.sedes;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    textoEstado(estado) {
      switch (estado) {
        case 0:
          return "PENDIENTE";
        case 1:
          return "ATENDIDO-ENFERMERIA";
        case 2:
          return "ATENDIDO-MEDICO";
        case 3:
          return "NO ATENDIDO";
        default:
          return "PENDIENTE";
      }
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
}
</style>  